.Header
  width: 100%
  margin: 40px 0
  display: flex
  gap: 20px
  span
    flex: 100%
    padding: 5px
    font-size: 32px
    text-align: center
    border: 1px solid black
    border-radius: 5px
    box-shadow: 4px 4px black