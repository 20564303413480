.GameView
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  .game-parts
    height: 100%
    display: flex
    flex-wrap: wrap
    justify-content: center
    align-content: center
    gap: 30px 10px
    .block-btn
      position: relative
      align-self: flex-start
      .disabled
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        background-color: #00000039
        z-index: 99
  .test
    width: 100px
    height: 100px
  .green
    background-color: green
  .red
    background-color: red