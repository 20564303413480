.dissolving-object
  pointer-events: none
  position: absolute
  left: 0
  top: 0
  transform: translate(-50%, -50%)
  opacity: 0
  max-height: 300px
  max-width: 300px
  animation: dissolve-gif 2.5s ease-in-out
  z-index: 2

  @keyframes dissolve-gif
    0%
      opacity: 1
    100%
      opacity: 0

.answer-anim-bg
  pointer-events: none
  position: absolute
  top: 0
  bottom: 0
  right: 0
  left: 0
  opacity: 0
  animation: dissolve-bg 0.5s ease-in-out
  z-index: 1

  @keyframes dissolve-bg
    0%
      opacity: 0.5
    100%
      opacity: 0

@media (prefers-color-scheme: light)
  .answer-anim-bg
    &.correct
      background-color: #00FF00
    &.wrong
      background-color: #FF0000

@media (prefers-color-scheme: dark)
  .answer-anim-bg
    &.correct
      background-color: #00b300
    &.wrong
      background-color: #b30202