.ResultsView
  width: 100%
  height: 100%
  display: flex
  flex-direction: column
  align-items: center
  .results-content
    height: 100%
    display: flex
    flex-direction: column
    align-items: center
    gap: 40px
    .results
      display: flex
      flex-direction: column
      align-items: center
      gap: 10px
    .details
      table
        width: 100%
        border-spacing: 15px
        .row__1
          text-align: left