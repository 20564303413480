.App
  max-width: 400px
  height: 100vh
  margin: auto
  display: flex
  flex-direction: column
  padding: 0 10px
  .router
    flex-grow: 1
  .error
    text-decoration-line: underline
    text-decoration-style: wavy
    text-decoration-color: red

@media (max-width: 400px) 
  .App
    transform: scale(0.85)