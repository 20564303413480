@import url('https://fonts.googleapis.com/css2?family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap')
*
  margin: 0
  padding: 0
  box-sizing: border-box
:root
  color-scheme: light dark
body
  font-family: "Space Mono", monospace !important
  font-size: 18px !important
h4
  font-size: 32px
  width: 100%
  text-align: center
button, select
  font-family: "Space Mono", monospace
  font-size: 18px !important
  outline: none
  user-select: none

@media (prefers-color-scheme: light)
  body
    background-color: white
    color: black

  button, select
    color: black
    background-color: white

@media (prefers-color-scheme: dark)
  body
    background-color:#191919
    color: white

  button, select
    color: white
    background-color: #191919